import { tongke } from '@/axios'
class SelectPlan {
  /**
   * @functionName list
   * @param { Object }  params
   * @param { Object }  params.page
   * @param { Object }  params.size
   * @param { Object }  params.jhlx //计划类型
   * @param { Object }  params.jhksnd //计划开始年度
   * @Description   // 选任计划列表
   * @return { Object }
   */
  list(params) {
    return tongke.get('/quxian/xrjh', { params })
  }
  // 选任详情
  detail(id) {
    return tongke.get(`/quxian/xrjh/${id}`)
  }

  /**
   * @functionName create
   * @param { Object }
   * @param { String }  params.jhmc 计划名称
   * @param { String }  params.jhksnd 计划开始年度，格式：YYYY
   * @param { Number }  params.sjcxrs   随机抽选人数
   * @param { String }  params.bmrs 报名人数
   * @param { Array }  params.attach  附件
   * @param { String }  params.attach.name 附件名称
   * @param { String }  params.attach.path  附件地址
   * @Description
   * @return { Object }
   */
  // 新建增补计划
  create(params) {
    return tongke.post(`/quxian/zbjh`, params)
  }

  /**
   * @functionName update
   * @param { Object }
   * @param { String }  params.jhmc 计划名称
   * @param { String }  params.jhksnd 计划开始年度，格式：YYYY
   * @param { Number }  params.sjcxrs   随机抽选人数
   * @param { String }  params.bmrs 报名人数
   * @param { Array }  params.attach  附件
   * @param { String }  params.attach.name 附件名称
   * @param { String }  params.attach.path  附件地址
   * @Description
   * @return { Object }
   */
  // 更新增补计划
  update(params) {
    return tongke.put(`/quxian/zbjh/${params.id}`, params)
  }
  // 上传文件--文件类型是pdf
  loadPDF(params) {
    return tongke.post('/upload/attach', params)
  }
}
export default new SelectPlan()
